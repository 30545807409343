const e = React.createElement
const { useState } = React

import MediaCard from './mediacard.js'

const Media = () => {
    const cards = [
        {
            title: 'Recruitment'
            , date: '21 August 2018'
            , link: 'https://universesgames.com/videos/recruitment.mp4'
            , type: 'video'
        }
        , {
            title: 'Takedowns 1'
            , date: '16 April 2017'
            , link: 'https://universesgames.com/videos/takedowns.mp4'
            , type: 'video'
        }
        , {
            title: 'Bloopers 1'
            , date: '21 April 2017'
            , link: 'https://universesgames.com/videos/bloopers.mp4'
            , type: 'video'
        }
        , {
            title: '[RE] Playing PUBG'
            , date: '31 January 2018'
            , link: 'https://universesgames.com/videos/re-pubg.mp4'
            , type: 'video'
        }
        , {
            title: 'Chicken Dinner Recap 1'
            , date: '12 February 2018'
            , link: 'https://universesgames.com/videos/cdr1.mp4'
            , type: 'video'
        }
        , {
            title: 'Chicken Dinner Recap 2'
            , date: '12 February 2018'
            , link: 'https://universesgames.com/videos/cdr2.mp4'
            , type: 'video'
        }
        , {
            title: 'Chicken Dinner Recap 3'
            , date: '13 February 2018'
            , link: 'https://universesgames.com/videos/cdr3.mp4'
            , type: 'video'
        }
        , {
            title: 'Recap 1x Headshot and 4v1'
            , date: '14 July 2019'
            , link: 'https://universesgames.com/videos/recap.mp4'
            , type: 'video'
        }
        , {
            title: 'Varlibear'
            , date: '28 November 2019'
            , link: 'https://universesgames.com/videos/varlibear.mp4'
            , type: 'video'
        }
        , {
            title: 'BRDM Win'
            , date: '15 December 2019'
            , link: 'https://universesgames.com/videos/BRDM Win.mp4'
            , type: 'video'
        }
    ]

    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'columns' }, [
            e( 'div', { className: 'column has-text-centered' }, [
                e( 'h1', { className: 'title', style: { color: 'ghostwhite' } }, 'Media' )
                , e( 'h1', { className: 'subtitle', style: { color: 'ghostwhite' } }, [
                    e( 'span', {}, 'Check us out on ' )
                    , e( 'a', { href: 'https://www.youtube.com/channel/UCZTI-z9kXuEJVpc-OjqHmig', target: '_blank' }, 'YouTube' )
                    , e( 'span', {}, '.' )
                ])
            ])
        ])
        , e( 'div', { id: 'app', className: 'row columns is-multiline' }, cards.map( c => e( 'div', { className: 'column is-4' }, [ e( MediaCard, c ) ]) ) )
    ])
}

export default Media
