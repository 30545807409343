const e = React.createElement

import Login from './login.js'

const LoginBtn = ( props ) => {
    const { config, changeView, setProfile } = props

    const onClick = ev => {
        changeView( e( Login, { config, changeView, setProfile } ) )
    }

    return e( 'a', { className: 'button is-info', onClick }, 'Log In' )
}

export default LoginBtn
