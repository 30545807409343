const e = React.createElement

import Register from './register.js'

const RegisterBtn = ( props ) => {
    const { config, changeView, setProfile } = props

    const onClick = ev => {
        changeView( e( Register, { config, changeView, setProfile } ) )
    }

    return e( 'a', { className: 'button is-info', onClick }, 'Register' )
}

export default RegisterBtn
