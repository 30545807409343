const e = React.createElement
const { useState } = React

import Profile from './profile.js'
import QRCode from './qrCode.js'

const Register = props => {
    const { config, changeView, setProfile } = props

    const [ passwordType, setPasswordType ] = useState( 'password' )

    const [ username, setUsername ] = useState( '' )
    const [ usernameErr, setUsernameErr ] = useState( false )
    const [ password, setPassword ] = useState( '' )
    const [ passwordErr, setPasswordErr ] = useState( false )
    const [ confirmPassword, setConfirmPassword ] = useState( '' )
    const [ confirmPasswordErr, setConfirmPasswordErr ] = useState( false )
    const [ errorMessage, setErrorMessage ] = useState( 'Something went wrong.' )
    const [ valid, setValid ] = useState( true )
    const [ registering, setRegistering ] = useState( false )

    const handleUsernameChange = e => {
        setUsername( e.target.value )
    }

    const handlePasswordChange = e => {
        setPassword( e.target.value )
    }

    const handleConfirmPasswordChange = e => {
        setConfirmPassword( e.target.value )
    }

    const onSubmit = ev => {
        ev.preventDefault()

        let isValid = true

        if ( username == '' ) {
            setUsernameErr( `${ passwordType == 'totp' ? 'Key' : 'Username' } is required.` )
            isValid = false
        } else { setUsernameErr( false ) }

        if ( passwordType != 'totp' && password == '' ) {
            setPasswordErr( 'Password is required.' )
            isValid = false
        } else { setPasswordErr( false ) }

        if ( passwordType != 'totp' && confirmPassword == '' ) {
            setConfirmPasswordErr( 'You must confirm your password.' )
            isValid = false
        } else if ( passwordType != 'totp' && confirmPassword != password ) {
            setConfirmPasswordErr( 'Your passwords do not match.' )
            isValid = false
        } else { setConfirmPasswordErr( false ) }

        if ( !isValid ) return

        setRegistering( true )

        fetch( `/register/${ passwordType }/${ username }${ password ? '/'+password : '' }`, { credentials: 'same-origin' } )
        .then( res => {
            setRegistering( false )

            if ( !res.ok ) return setValid( false )

            res.json()
            .then( data => {
                if ( data.err ) {
                    setErrorMessage( data.msg )
                    setValid( false )
                    return
                }

                setProfile( data.profile )

                if ( passwordType == 'totp' ) return changeView( e( QRCode, { qrCode: data.msg, profile: data.profile } ) )
                changeView( e( Profile, { config, profile: data.profile, setProfile, changeView } ) )
            })
        })
    }

    const usernameErrMsg = usernameErr ? e( 'p', { className: 'help is-danger' }, usernameErr ) : ''
    const passwordErrMsg = passwordErr ? e( 'p', { className: 'help is-danger' }, passwordErr ) : ''
    const confirmPasswordErrMsg = confirmPasswordErr ? e( 'p', { className: 'help is-danger' }, confirmPasswordErr ) : ''
    const registerErrMsg = valid ? '' : e( 'article', { className: 'message is-danger' }, [
        e( 'div', { className: 'message-body' }, errorMessage )
    ])

    const renderSimplePasswordForm = () => {
        return e( 'form', { className: 'form', onSubmit }, [
            e( 'div', { className: 'field' }, [
                e( 'label', { className: 'label' }, 'Username' )
                , e( 'div', { className: 'control has-icons-left' }, [
                    e( 'input', { className: `input ${ usernameErr ? 'is-danger' : '' }`, name: 'username', value: username, type: 'text', placeholder: 'Username', onChange: handleUsernameChange } )
                    , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-user' } ) )
                ])
                , usernameErrMsg
            ])
            , e( 'div', { className: 'field' }, [
                e( 'label', { className: 'label' }, 'Password' )
                , e( 'div', { className: 'control has-icons-left' }, [
                    e( 'input', { className: `input ${ passwordErr ? 'is-danger' : '' }`, name: 'password', value: password, type: 'password', placeholder: 'Password', onChange: handlePasswordChange } )
                    , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-lock' } ) )
                ])
                , passwordErrMsg
            ])
            , e( 'div', { className: 'field' }, [
                e( 'label', { className: 'label' }, 'Confirm Password' )
                , e( 'div', { className: 'control has-icons-left' }, [
                    e( 'input', { className: `input ${ confirmPasswordErr ? 'is-danger' : '' }`, name: 'password', value: confirmPassword, type: 'password', placeholder: 'Confirm Password', onChange: handleConfirmPasswordChange } )
                    , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-lock' } ) )
                ])
                , confirmPasswordErrMsg
            ])
            , e( 'div', { className: 'field' }, [
                e( 'button', { type: 'submit', className: `button is-success ${ registering ? 'is-loading' : '' }` }, 'Register' )
            ])
        ])
    }

    const renderTotpPasswordForm = () => {
        return e( 'form', { className: 'form', onSubmit }, [
            e( 'div', { className: 'field' }, [
                e( 'label', { className: 'label' }, 'Key' )
                , e( 'div', { className: 'control has-icons-left' }, [
                    e( 'input', { className: `input ${ usernameErr ? 'is-danger' : '' }`, name: 'username', value: username, type: 'text', placeholder: 'Key', onChange: handleUsernameChange } )
                    , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-key' } ) )
                ])
                , usernameErrMsg
            ])
            , e( 'div', { className: 'field' }, [
                e( 'button', { type: 'submit', className: `button is-success ${ registering ? 'is-loading' : '' }` }, 'Register' )
            ])
        ])
    }

    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'columns is-centered' }, [
            e( 'div', { className: 'column is-5-tablet is-4-desktop is-3-widescreen' }, [
                e( 'div', { className: 'field' }, [
                    e( 'label', { className: 'label' }, 'Password Type' )
                    , e( 'div', { className: 'control' }, [
                        e( 'div', { className: 'select is-primary' }, [
                            e( 'select', { onChange: ev => setPasswordType( ev.target.value ) }, [
                                e( 'option', { value: 'password' }, 'Simple' )
                                , e( 'option', { value: 'totp' }, 'TOTP' )
                            ])
                        ])
                    ])
                ])
                , passwordType == 'password' ? renderSimplePasswordForm() : renderTotpPasswordForm()
                , registerErrMsg
            ])
        ])
    ])
}

export default Register
