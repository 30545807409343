const e = React.createElement
const { useState } = React

const Footer = () => {
    return e( 'footer', { className: 'footer' }, [
        e( 'div', { className: 'container' }, [
            e( 'div', { className: 'content has-text-centered' }, [
                e( 'div', { className: 'soc' }, [
                    e( 'a', { className: 'icon', href: 'https://bitbucket.org/universesgames/', target: '_blank' }, [
                        e( 'i', { className: 'fa-brands fa-bitbucket fa-lg' } )
                    ])
                    , e( 'a', { className: 'icon', href: 'https://www.youtube.com/channel/UCZTI-z9kXuEJVpc-OjqHmig', target: '_blank' }, [
                        e( 'i', { className: 'fa-brands fa-youtube fa-lg' } )
                    ])
                    , e( 'a', { className: 'icon', href: 'https://www.discord.gg/5BBFzyH', target: '_blank' }, [
                        e( 'i', { className: 'fa-brands fa-discord fa-lg' } )
                    ])
                ])
                , e( 'p', {}, [
                    e( 'strong', {}, `© ${ new Date().getFullYear() } Universes Games` )
                ])
            ])
        ])
    ])
}

export default Footer
