const e = React.createElement
const { useState, useEffect } = React

const Donate = () => {
    const $script = document.createElement( 'script' )
    $script.src = 'https://js.stripe.com/v3/pricing-table.js'
    document.body.appendChild( $script )

    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'columns' }, [
            e( 'div', { className: 'column has-text-centered' }, [
                e( 'h1', { className: 'title', style: { color: 'ghostwhite' } }, 'Donate' )
            ])
        ])
        , e( 'a', { href: 'https://donate.stripe.com/28oaHqbDWdukgCsbII', target: '_blank' }, 'Make a one-time donation.' )
        //, e( 'script', { async: true, src: 'https://js.stripe.com/v3/pricing-table.js' } )
        , e( 'stripe-pricing-table', {
            'pricing-table-id': 'prctbl_1Me37dEduN4PsPpHmZBWpw9s'
            , 'publishable-key': 'pk_live_51Hj8OzEduN4PsPpHzhTyJ3zkOPvrxBUN96HaFYFd8Y6zX69psbyL9fRZpVINpiByVpi1Yvw7Pnm3l07Lz6akY5JR00hu8fmYSw'
        })
    ])
}

export default Donate
