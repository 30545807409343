const e = React.createElement
const { useState, useEffect } = React

import Navbar from './navbar.js'
import Footer from './footer.js'
import Brands from './brands.js'

const App = () => {
    const [ view, changeView ] = useState( e( Brands ) )
    const [ profile, setProfile ] = useState( null )
    const [ config, setConfig ] = useState({
        topics: [
            'AoEII'
            , 'Dark and Darker'
            , 'Discord'
            , 'Epic'
            , 'General'
            , 'Gorgon'
            , 'LoL'
            , 'Origin'
            , 'Pantheon'
            , 'PUBG'
            , 'Riot'
            , 'R6'
            , 'Steam'
            , 'Tarkov'
            , 'Uplay'
            , 'WA'
            , 'WoW'
        ]
        , roles: [
            {
                role: 'fullAdmin'
                , label: 'Full Admin'
            }
            , {
                role: 'adminMembers'
                , label: 'Administer Members'
            }
            , {
                role: 'adminTwitter'
                , label: 'Administer Twitter Feeds'
            }
            , {
                role: 'adminResources'
                , label: 'Administer Resources'
            }
        ]
    })

    // do on first render only
    useEffect( () => {
        fetch( '/profileData', { credentials: 'same-origin' } )
        .then( res => {
            if ( res.ok ) {
                res.json()
                .then( data => setProfile( data ) )
            }
        })
    }, [] )

    return e( 'div', {}, [
        e( Navbar, { active: 'home', profile, config, setProfile, view, changeView } )
        , e( 'div', { className: 'container' }, view )
        , e( Footer, { config } )
    ])
}

ReactDOM.render( React.createElement( App ), document.getElementById( 'root' ) )
