const e = React.createElement
const { useState } = React

import Profile from './profile.js'

const Login = props => {
    const { config, changeView, setProfile } = props

    const [ username, setUsername ] = useState( '' )
    const [ usernameErr, setUsernameErr ] = useState( false )
    const [ password, setPassword ] = useState( '' )
    const [ passwordErr, setPasswordErr ] = useState( false )
    const [ valid, setValid ] = useState( true )
    const [ authenticating, setAuthenticating ] = useState( false )

    const handleUsernameChange = e => {
        setUsername( e.target.value.trim() )
    }

    const handlePasswordChange = e => {
        setPassword( e.target.value )
    }

    const onSubmit = ev => {
        ev.preventDefault()

        let isValid = true

        if ( username == '' ) {
            setUsernameErr( 'Username is required.' )
            isValid = false
        } else { setUsernameErr( false ) }

        if ( password == '' ) {
            setPasswordErr( 'Password is required.' )
            isValid = false
        } else { setPasswordErr( false ) }

        if ( !isValid ) return

        setAuthenticating( true )

        fetch( `/authenticate/${ username }/${ password }`, { credentials: 'same-origin' } )
        .then( res => {
            setAuthenticating( false )

            if ( !res.ok ) return setValid( false )

            res.json()
            .then( profile => {
                setProfile( profile )
                changeView( e( Profile, { config, profile, setProfile, changeView } ) )
            })
        })

    }

    const usernameErrMsg = usernameErr ? e( 'p', { className: 'help is-danger' }, usernameErr ) : ''
    const passwordErrMsg = passwordErr ? e( 'p', { className: 'help is-danger' }, passwordErr ) : ''
    const loginErrMsg = valid ? '' : e( 'article', { className: 'message is-danger' }, [
        e( 'div', { className: 'message-body' }, 'Unable to authenticate.' )
    ])

    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'columns is-centered' }, [
            e( 'div', { className: 'column is-5-tablet is-4-desktop is-3-widescreen' }, [
                e( 'form', { className: 'form', onSubmit }, [
                    e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Username' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ usernameErr ? 'is-danger' : '' }`, name: 'username', value: username, type: 'text', placeholder: 'Username', onChange: handleUsernameChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-user' } ) )
                        ])
                        , usernameErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Password' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ passwordErr ? 'is-danger' : '' }`, name: 'password', value: password, type: 'password', placeholder: 'Password', onChange: handlePasswordChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-lock' } ) )
                        ])
                        , passwordErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'button', { type: 'submit', className: `button is-success ${ authenticating ? 'is-loading' : '' }` }, 'Log In' )
                    ])
                ])
                , loginErrMsg
            ])
        ])
    ])
}

export default Login
