const e = React.createElement
const { useState, useEffect } = React

const NewAttributeModal = props => {
    const { modalActive, setModalActive, profile, config, editing, setSuccessMessage, setErrorMessage, loadAttributes } = props

    const defaults = {
        topic: 'General'
        , key: ''
        , value: ''
        , notes: ''
    }

    const [ topic, setTopic ] = useState( editing ? editing.topic : defaults.topic )
    const [ topicErr, setTopicErr ] = useState( false )
    const [ key, setKey ] = useState( editing ? editing.key : defaults.key )
    const [ keyErr, setKeyErr ] = useState( false )
    const [ value, setValue ] = useState( editing ? editing.value : defaults.value )
    const [ valueErr, setValueErr ] = useState( false )
    const [ notes, setNotes ] = useState( editing ? editing.notes : defaults.notes )
    const [ saving, setSaving ] = useState( false )

    // this allows form to update when editing state is set on the parent
    useEffect( () => {
        setTopic( editing ? editing.topic : defaults.topic )
        setKey( editing ? editing.key : defaults.key )
        setValue( editing ? editing.value : defaults.value )
        setNotes( editing ? editing.notes : defaults.notes )
    }, [ props ] )

    const handleTopicChange = ev => {
        setTopic( ev.target.value )
    }

    const handleKeyChange = ev => {
        setKey( ev.target.value )
    }

    const handleValueChange = ev => {
        setValue( ev.target.value )
    }

    const handleNotesChange = ev => {
        setNotes( ev.target.value )
    }

    const onSubmit = ev => {
        ev.preventDefault()
        
        let isValid = true

        if ( topic == '' ) {
            setTopicErr( 'Topic is required.' )
            isValid = false
        } else { setTopicErr( false ) }

        if ( key == '' ) {
            setKeyErr( 'Key is required.' )
            isValid = false
        } else { setKeyErr( false ) }

        if ( value == '' ) {
            setValueErr( 'Value is required.' )
            isValid = false
        } else { setValueErr( false ) }

        if ( !isValid ) return

        setSaving( true )

        fetch( editing ? `/attributes/update/${ editing._id }` : '/attributes/commit', {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                topic
                , key
                , value
                , notes
                , member: profile._id
            })
        })
        .then( res => {
            const msg = editing ? `${ topic } attribute "${ key }" saved.` : `New ${ topic } attribute "${ key }" created!`

            res.ok ? setSuccessMessage( msg ) : setErrorMessage( 'Something went wrong.' )
            
            setSaving( false )
            setModalActive( false )
            loadAttributes()
        })
    }

    const closeModal = ev => {
        setModalActive( false )
    }

    const topicErrMsg = topicErr ? e( 'p', { className: 'help is-danger' }, topicErr ) : ''
    const keyErrMsg = keyErr ? e( 'p', { className: 'help is-danger' }, keyErr ) : ''
    const valueErrMsg = valueErr ? e( 'p', { className: 'help is-danger' }, valueErr ) : ''

    return e( 'div', { className: `modal ${ modalActive ? 'is-active' : '' }` }, [
        e( 'div', { className: 'modal-background' } )
        , e( 'div', { className: 'modal-card' }, [
            e( 'header', { className: 'modal-card-head' }, [
                e( 'p', { className: 'modal-card-title' }, `${ editing ? 'Editing' : 'New' } Attribute` )
                , e( 'button', { className: 'delete', onClick: closeModal } )
            ])
            , e( 'section', { className: 'modal-card-body' }, [
                e( 'form', { className: 'form', onSubmit }, [
                    e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Topic' )
                        , e( 'div', { className: 'control' }, [
                            e( 'div', { className: `select ${ topicErr ? 'is-danger' : '' }` }, [
                                e( 'select', { value: topic, onChange: handleTopicChange }, config.topics.map( topic => e( 'option', { value: topic }, topic ) ) )
                            ])
                        ])
                        , topicErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Attribute' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ keyErr ? 'is-danger' : '' }`, name: 'key', value: key, type: 'text', placeholder: 'Attribute', onChange: handleKeyChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-tag' } ) )
                        ])
                        , keyErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Value' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ valueErr ? 'is-danger' : '' }`, name: 'value', value: value, type: 'text', placeholder: 'Value', onChange: handleValueChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-align-justify' } ) )
                        ])
                        , valueErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Notes' )
                        , e( 'div', { className: 'control' }, [
                            e( 'textarea', { className: 'textarea', name: 'notes', value: notes, placeholder: 'Notes', onChange: handleNotesChange } )
                        ])
                    ])
                ])
            ])
            , e( 'footer', { className: 'modal-card-foot' }, [
                e( 'button', { className: `button is-success ${ saving ? 'is-loading' : '' }`, onClick: onSubmit }, 'Save' )
                , e( 'button', { className: 'button', onClick: closeModal }, 'Cancel' )
            ])
        ])
    ])
}

const ProfileAttributes = props => {
    const { profile, config } = props

    const [ attributes, setAttributes ] = useState( [] )
    const [ modalActive, setModalActive ] = useState( false )
    const [ errorMessage, setErrorMessage ] = useState( false )
    const [ successMessage, setSuccessMessage ] = useState( false )
    const [ editing, setEditing ] = useState( false )

    const loadAttributes = () => {
        fetch( `/attributes/list/${ profile._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'There was a problem loading your attributes.' )

            res.json().then( data => setAttributes( data ) )
        })
    }

    useEffect( () => loadAttributes(), [] )

    const deleteAttribute = ( attrib, ev ) => {
        fetch( `/attributes/delete/${ attrib._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'Attribute not deleted.' )

            setAttributes( attributes.filter( a => a != attrib ) )
        })
    }

    const newAttribute = ev => {
        setEditing( false )
        setModalActive( true )
    }

    const editAttribute = ( attrib, ev ) => {
        setEditing( attrib )
        setModalActive( true )
    }

    return e( 'div', { className: 'section' }, [
        e( 'p', { className: 'title is-4' }, 'Attributes' )
        , e( 'div', { className: 'column is-6' }, [
            successMessage ? e( 'article', { className: 'message is-success' }, e( 'div', { className: 'message-body' }, successMessage ) ) : ''
            , errorMessage ? e( 'article', { className: 'message is-danger' }, e( 'div', { className: 'message-body' }, errorMessage ) ) : ''
        ])
        , e( 'button', { className: 'button is-link' }, [
            e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-plus' } ) )
            , e( 'span', { onClick: newAttribute }, 'New Attribute' )
        ])
        , e( 'br' )
        , e( 'table', { className: 'table is-striped' }, [
            e( 'thead', {}, [
                e( 'tr', {}, [
                    e( 'th', {}, 'Topic' )
                    , e( 'th', {}, 'Attribute' )
                    , e( 'th', {}, 'Value' )
                    , e( 'th', {}, 'Notes' )
                    , e( 'th', {}, 'Server Notes' )
                    , e( 'th', {}, 'Tools' )
                ])
            ])
            , e( 'tbody', {}, attributes.map( attrib => e( 'tr', {}, [
                e( 'td', {}, attrib.topic )
                , e( 'td', {}, attrib.key )
                , e( 'td', {}, attrib.value )
                , e( 'td', {}, attrib.notes )
                , e( 'td', {}, attrib.serverMessage )
                , e( 'td', { style: { whiteSpace: 'nowrap' } }, [
                    e( 'button', { className: 'button is-info', onClick: ev => editAttribute( attrib, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-wrench fa-lg' } ) ) )
                    , e( 'button', { className: 'button is-danger', onClick: ev => deleteAttribute( attrib, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-trash fa-lg' } ) ) )
                ])
            ])))
        ])
        , e( NewAttributeModal, { modalActive, setModalActive, profile, config, editing, setSuccessMessage, setErrorMessage, loadAttributes } )
    ])
}

export default ProfileAttributes
