const e = React.createElement
const { useState } = React

import ProfileSummary from './profileSummary.js'
import ProfileAttributes from './profileAttributes.js'
import AdminMembers from './adminMembers.js'
import AdminTwitter from './adminTwitter.js'
import AdminResources from './adminResources.js'

const Profile = props => {
    let { profile, setProfile, config, changeView } = props

    const [ active, setActive ] = useState( 'summary' )
    const [ tab, setTab ] = useState( e( ProfileSummary, { profile, changeView } ) )

    const handleChangeToSummary = ev => {
        setActive( 'summary' )
        setTab( e( ProfileSummary, { profile, changeView } ) )
    }

    const handleChangeToAttributes = ev => {
        setActive( 'attributes' )
        setTab( e( ProfileAttributes, { profile, config } ) )
    }

    const handleChangeToAdminMembers = ev => {
        setActive( 'admin_members' )
        setTab( e( AdminMembers, { profile, config } ) )
    }

    const handleChangeToAdminTwitter = ev => {
        setActive( 'admin_twitter' )
        setTab( e( AdminTwitter, { profile } ) )
    }

    const handleChangeToAdminResources = ev => {
        setActive( 'admin_resources' )
        setTab( e( AdminResources , { profile, config } ) )
    }

    const isMembersAdmin = profile.roles.indexOf( 'adminMembers' ) >= 0 || profile.roles.indexOf( 'fullAdmin' ) >= 0
    const isTwitterAdmin = profile.roles.indexOf( 'adminTwitter' ) >= 0 || profile.roles.indexOf( 'fullAdmin' ) >= 0
    const isResourcesAdmin = profile.roles.indexOf( 'adminResources' ) >= 0 || profile.roles.indexOf( 'fullAdmin' ) >= 0

    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'tabs' }, [
            e( 'ul', {}, [
                e( 'li', { className: `${ active == 'summary' ? 'is-active' : '' }` }, e( 'a', { onClick: handleChangeToSummary }, [
                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: 'fa-solid fa-user' } ) )
                    , e( 'span', {}, 'Profile' )
                ]) )
                , e( 'li', { className: `${ active == 'attributes' ? 'is-active' : '' }` }, e( 'a', { onClick: handleChangeToAttributes }, [
                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: 'fa-solid fa-list' } ) )
                    , e( 'span', {}, 'Attributes' )
                ]) )
                , isMembersAdmin ? e( 'li', { className: `${ active == 'admin_members' ? 'is-active' : '' }` }, e( 'a', { onClick: handleChangeToAdminMembers }, [
                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: 'fa-solid fa-users-gear' } ) )
                    , e( 'span', {}, 'Administer Members' )
                ]) ) : ''
                , isTwitterAdmin ? e( 'li', { className: `${ active == 'admin_twitter' ? 'is-active' : '' }` }, e( 'a', { onClick: handleChangeToAdminTwitter }, [
                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: 'fa-brands fa-twitter' } ) )
                    , e( 'span', {}, 'Administer Twitter Feeds' )
                ]) ) : ''
                , isResourcesAdmin ? e( 'li', { className: `${ active == 'admin_resources' ? 'is-active' : '' }` }, e( 'a', { onClick: handleChangeToAdminResources }, [
                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: 'fa-solid fa-box-archive' } ) )
                    , e( 'span', {}, 'Administer Resources' )
                ]) ) : ''
            ])
        ])
        , tab
    ])
}

export default Profile
