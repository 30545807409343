const e = React.createElement
const { useState, useEffect } = React

const NewEditFeedModal = props => {
    const { modalActive, setModalActive, editing, setSuccessMessage, setErrorMessage, loadFeeds } = props

    const defaults = {
        screenName: ''
        , queries: ''
    }

    const [ screenName, setScreenName ] = useState( editing ? editing.screenName : defaults.screenName )
    const [ screenNameErr, setScreenNameErr ] = useState( false )
    const [ queries, setQueries ] = useState( editing ? editing.queries : defaults.queries )
    const [ queriesErr, setQueriesErr ] = useState( false )
    const [ saving, setSaving ] = useState( false )

    // this allows form to update when editing state is set on the parent
    useEffect( () => {
        setScreenName( editing ? editing.screenName : defaults.screenName )
        setQueries( editing ? editing.queries : defaults.queries )
    }, [ props ] )

    const handleScreenNameChange = ev => {
        setScreenName( ev.target.value )
    }

    const handleQueriesChange = ev => {
        setQueries( ev.target.value )
    }

    const onSubmit = ev => {
        ev.preventDefault()
        
        let isValid = true

        if ( screenName == '' ) {
            setScreenNameErr( 'Screen name is required.' )
            isValid = false
        } else { setScreenNameErr( false ) }

        if ( queries == '' ) {
            setQueriesErr( 'Queries is required.' )
            isValid = false
        } else { setQueriesErr( false ) }

        if ( !isValid ) return

        setSaving( true )

        fetch( editing ? `/feeds/update/${ editing._id }` : '/feeds/commit', {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                screenName
                , queries
            })
        })
        .then( res => {
            const msg = editing ? `@${ screenName } feed saved.` : `New @${ screenName } feed created!`

            res.ok ? setSuccessMessage( msg ) : setErrorMessage( 'Something went wrong.' )
            
            setSaving( false )
            setModalActive( false )
            loadFeeds()
        })
    }

    const closeModal = ev => {
        setModalActive( false )
    }

    const screenNameErrMsg = screenNameErr ? e( 'p', { className: 'help is-danger' }, screenNameErr ) : ''
    const queriesErrMsg = queriesErr ? e( 'p', { className: 'help is-danger' }, queriesErr ) : ''

    return e( 'div', { className: `modal ${ modalActive ? 'is-active' : '' }` }, [
        e( 'div', { className: 'modal-background' } )
        , e( 'div', { className: 'modal-card' }, [
            e( 'header', { className: 'modal-card-head' }, [
                e( 'p', { className: 'modal-card-title' }, `${ editing ? 'Editing' : 'New' } Twitter Feed` )
                , e( 'button', { className: 'delete', onClick: closeModal } )
            ])
            , e( 'section', { className: 'modal-card-body' }, [
                e( 'form', { className: 'form', onSubmit }, [
                    e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Screen Name (@screen_name without the @)' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ screenNameErr ? 'is-danger' : '' }`, name: 'screenName', value: screenName, type: 'text', placeholder: 'Screen Name', onChange: handleScreenNameChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-brands fa-twitter' } ) )
                        ])
                        , screenNameErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Twitter Search Queries (comma-separated, use standard v1 operators)' )
                        , e( 'a', { href: 'https://developer.twitter.com/en/docs/twitter-api/v1/tweets/search/guides/standard-operators', target: '_blank' }, 'Twitter Search Guide - Standard Operators' )
                        , e( 'div', { className: 'control' }, [
                            e( 'textarea', { className: `textarea ${ queriesErr ? 'is-danger' : '' }`, name: 'queries', value: queries, placeholder: 'Queries', onChange: handleQueriesChange } )
                        ])
                        , queriesErrMsg
                    ])
                ])
            ])
            , e( 'footer', { className: 'modal-card-foot' }, [
                e( 'button', { className: `button is-success ${ saving ? 'is-loading' : '' }`, onClick: onSubmit }, 'Save' )
                , e( 'button', { className: 'button', onClick: closeModal }, 'Cancel' )
            ])
        ])
    ])
}

const AdminTwitter = props => {
    const { profile } = props

    const [ feeds, setFeeds ] = useState( [] )
    const [ modalActive, setModalActive ] = useState( false )
    const [ errorMessage, setErrorMessage ] = useState( false )
    const [ successMessage, setSuccessMessage ] = useState( false )
    const [ editing, setEditing ] = useState( false )

    const loadFeeds = () => {
        fetch( '/feeds/list', { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'There was a problem loading Twitter feeds.' )

            res.json().then( data => setFeeds( data ) )
        })
    }

    useEffect( () => loadFeeds(), [] )

    const deleteFeed = ( feed, ev ) => {
        fetch( `/feeds/delete/${ feed._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'Feed not deleted.' )

            setFeeds( feeds.filter( f => f != feed ) )
        })
    }

    const newFeed = ev => {
        setEditing( false )
        setModalActive( true )
    }

    const editFeed = ( feed, ev ) => {
        setEditing( feed )
        setModalActive( true )
    }

    return e( 'div', { className: 'section' }, [
        e( 'p', { className: 'title is-4' }, 'Twitter Feeds' )
        , e( 'div', { className: 'column is-6' }, [
            successMessage ? e( 'article', { className: 'message is-success' }, e( 'div', { className: 'message-body' }, successMessage ) ) : ''
            , errorMessage ? e( 'article', { className: 'message is-danger' }, e( 'div', { className: 'message-body' }, errorMessage ) ) : ''
        ])
        , e( 'button', { className: 'button is-link' }, [
            e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-plus' } ) )
            , e( 'span', { onClick: newFeed }, 'New Twitter Feed' )
        ])
        , e( 'br' )
        , e( 'table', { className: 'table is-striped' }, [
            e( 'thead', {}, [
                e( 'tr', {}, [
                    e( 'th', {}, 'Screen Name' )
                    , e( 'th', {}, 'Twitter Standard Queries (comma-separated)' )
                    , e( 'th', {}, 'Tools' )
                ])
            ])
            , e( 'tbody', {}, feeds.map( feed => e( 'tr', {}, [
                e( 'td', {}, feed.screenName )
                , e( 'td', {}, feed.queries )
                , e( 'td', {}, [
                    e( 'button', { className: 'button is-info', onClick: ev => editFeed( feed, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-wrench fa-lg' } ) ) )
                    , e( 'button', { className: 'button is-danger', onClick: ev => deleteFeed( feed, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-trash fa-lg' } ) ) )
                ])
            ])))
        ])
        , e( NewEditFeedModal, { modalActive, setModalActive, editing, setSuccessMessage, setErrorMessage, loadFeeds } )
    ])
}

export default AdminTwitter
