const e = React.createElement
const { useState, useEffect } = React

import Brands from './brands.js'
import Media from './media.js'
import Resources from './resources.js'
import Donate from './donate.js'

import LoginBtn from './loginBtn.js'
import RegisterBtn from './registerBtn.js'
import ProfileBtn from './profileBtn.js'

const Navbar = ( props ) => {
    const { view, changeView, profile, config, setProfile } = props

    const [ active, setActive ] = useState( props.active )
    const [ usrBtn, setUsrBtn ] = useState( '' )

    const setProfileOrLogin = () => {
        // always show profile button if logged in
        // otherwise only show register button on login page
        setUsrBtn(
            profile
                ? e( ProfileBtn, { profile, config, changeView } )
                : ( usrBtn && view.type.name == 'Login' )
                    ? e( RegisterBtn, { config, changeView, setProfile } )
                    : e( LoginBtn, { config, changeView, setProfile } )
        )
    }

    // run when props change (eg. a profile is loaded)
    useEffect( () => {
        setProfileOrLogin()
    }, [ props ] )

    const handleHomeLnkPress = ev => {
        setActive( 'home' )
        changeView( e( Brands ) )
    }

    const handleMediaLnkPress = ev => {
        setActive( 'media' )
        changeView( e( Media ) )
    }

    const handleResourcesLnkPress = ev => {
        setActive( 'resources' )
        changeView( e( Resources ) )
    }

    const handleDonateLnkPress = ev => {
        setActive( 'donate' )
        changeView( e( Donate ) )
    }

    return e( 'nav', { className: 'navbar' }, [
        e( 'div', { className: 'navbar-brand' }, [
            e( 'a', { className: 'navbar-item', onClick: handleHomeLnkPress }, [
                e( 'img', { src: './img/uni_xpnt.png', className: 'uni-logo', alt: 'Universes Games Logo', width: 60 } )
            ])
            , e( 'a', { className: 'navbar-item', onClick: handleHomeLnkPress }, [
                e( 'span', { className: 'uni-ug-font', style: { fontSize: '2em' } }, 'Universes Games' )
            ])
        ])
        , e( 'div', { className: 'navbar-menu' }, [
            e( 'div', { className: 'navbar-end' }, [
                e( 'a', { className: `navbar-item ${ active == 'home' ? 'is-active' : '' }`, onClick: handleHomeLnkPress }, 'Home' )
                , e( 'a', {className: `navbar-item ${ active == 'media' ? 'is-active' : '' }`, onClick: handleMediaLnkPress }, 'Media' )
                , e( 'a', { className: `navbar-item ${ active == 'resources' ? 'is-active' : '' }`, onClick: handleResourcesLnkPress }, 'Resources' )
                , e( 'a', { className: `navbar-item ${ active == 'donate' ? 'is-active' : '' }`, onClick: handleDonateLnkPress }, 'Donate' )
                , e( 'div', { className: 'navbar-item' }, usrBtn )
            ])
        ])
    ])
}

export default Navbar
