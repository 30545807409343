const e = React.createElement
const { useState, useEffect } = React

const Resources = () => {
    const [ resources, setResources ] = useState( [] )

    const loadResources = () => {
        fetch( '/resources/list/', { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return

            res.json().then( data => setResources( data ) )
        })
    }

    useEffect( () => loadResources(), [] )

    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'columns' }, [
            e( 'div', { className: 'column has-text-centered' }, [
                e( 'h1', { className: 'title', style: { color: 'ghostwhite' } }, 'Resources' )
            ])
        ])
        , e( 'table', { className: 'table is-striped' }, [
            e( 'thead', {}, e( 'tr', {}, [
                e( 'th', {}, 'Name' )
                , e( 'th', {}, 'Description' )
                , e( 'th', {}, 'Category' )
                , e( 'th', {}, 'Size' )
                , e( 'th', { style: { whiteSpace: 'nowrap' } }, 'Account Needed?' )
                , e( 'th', {}, '' )
            ]) )
            , e( 'tbody', {}, resources.map( r => e( 'tr', {}, [
                e( 'td', { style: { whiteSpace: 'nowrap' } }, r.name )
                , e( 'td', {}, r.description )
                , e( 'td', { style: { whiteSpace: 'nowrap' } }, r.category )
                , e( 'td', { style: { whiteSpace: 'nowrap' } }, r.size )
                , e( 'td', {}, r.accountNeeded ? 'Yes' : 'No' )
                , e( 'td', {}, [
                    e( 'a', { className: 'button is-link', href: r.link, target: '_blank' }, [
                        e( 'span', { className: 'icon' }, [
                            e( 'i', { className: 'fa-solid fa-download' } )
                        ])
                        , e( 'span', {}, 'Download' )
                    ])
                ])
            ]) ) )
        ])
    ])
}

export default Resources
