const e = React.createElement
const { useState, useEffect } = React

const NewEditResourceModal = props => {
    const { modalActive, setModalActive, editing, setSuccessMessage, setErrorMessage, loadResources, config } = props

    const defaults = {
        name: ''
        , description: ''
        , category: 'General'
        , link: ''
        , accountNeeded: false
    }

    const [ name, setName ] = useState( editing ? editing.name : defaults.name )
    const [ nameErr, setNameErr ] = useState( false )
    const [ description, setDescription ] = useState( editing ? editing.description : defaults.description )
    const [ category, setCategory ] = useState( editing ? editing.category : defaults.category )
    const [ categoryErr, setCategoryErr ] = useState( false )
    const [ link, setLink ] = useState( editing ? editing.link : defaults.link )
    const [ linkErr, setLinkErr ] = useState( false )
    const [ accountNeeded, setAccountNeeded ] = useState( editing ? editing.accountNeeded : defaults.accountNeeded )
    const [ saving, setSaving ] = useState( false )

    // this allows form to update when editing state is set on the parent
    useEffect( () => {
        setName( editing ? editing.name : defaults.name )
        setDescription( editing ? editing.description : defaults.description )
        setCategory( editing ? editing.category : defaults.category )
        setLink( editing ? editing.link : defaults.link )
        setAccountNeeded( editing ? editing.accountNeeded : defaults.accountNeeded )
    }, [ props ] )

    const handleNameChange = ev => {
        setName( ev.target.value )
    }

    const handleDescriptionChange = ev => {
        setDescription( ev.target.value )
    }

    const handleCategoryChange = ev => {
        setCategory( ev.target.value )
    }

    const handleLinkChange = ev => {
        setLink( ev.target.value )
    }

    const handleAccountNeededChange = ev => {
        setAccountNeeded( ev.target.checked )
    }

    const onSubmit = ev => {
        ev.preventDefault()
        
        let isValid = true

        if ( name == '' ) {
            setNameErr( 'Name is required.' )
            isValid = false
        } else { setNameErr( false ) }

        if ( category == '' ) {
            setCategoryErr( 'Category is required.' )
            isValid = false
        } else { setCategoryErr( false ) }

        if ( link == '' ) {
            setLinkErr( 'Link is required.' )
            isValid = false
        } else { setLinkErr( false ) }

        if ( !isValid ) return

        setSaving( true )

        fetch( editing ? `/resources/update/${ editing._id }` : '/resources/commit', {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                name
                , description
                , category
                , link
                , accountNeeded
            })
        })
        .then( res => {
            const msg = editing ? `${ category } resource "${ name }" saved.` : `New ${ category } resource "${ name }" created!`

            res.ok ? setSuccessMessage( msg ) : setErrorMessage( 'Something went wrong.' )
            
            setSaving( false )
            setModalActive( false )
            loadResources()
        })
    }

    const closeModal = ev => {
        setModalActive( false )
    }

    const nameErrMsg = nameErr ? e( 'p', { className: 'help is-danger' }, nameErr ) : ''
    const categoryErrMsg = categoryErr ? e( 'p', { className: 'help is-danger' }, categoryErr ) : ''
    const linkErrMsg = linkErr ? e( 'p', { className: 'help is-danger' }, linkErr ) : ''

    return e( 'div', { className: `modal ${ modalActive ? 'is-active' : '' }` }, [
        e( 'div', { className: 'modal-background' } )
        , e( 'div', { className: 'modal-card' }, [
            e( 'header', { className: 'modal-card-head' }, [
                e( 'p', { className: 'modal-card-title' }, `${ editing ? 'Editing' : 'New' } Resource` )
                , e( 'button', { className: 'delete', onClick: closeModal } )
            ])
            , e( 'section', { className: 'modal-card-body' }, [
                e( 'form', { className: 'form', onSubmit }, [
                    e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Name' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ nameErr ? 'is-danger' : '' }`, name: 'name', value: name, type: 'text', placeholder: 'Name', onChange: handleNameChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-file' } ) )
                        ])
                        , nameErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Description' )
                        , e( 'div', { className: 'control' }, [
                            e( 'textarea', { className: 'textarea', name: 'description', value: description, placeholder: 'Description', onChange: handleDescriptionChange } )
                        ])
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Category' )
                        , e( 'div', { className: 'control' }, [
                            e( 'div', { className: `select ${ categoryErr ? 'is-danger' : '' }` }, [
                                e( 'select', { value: category, onChange: handleCategoryChange }, config.topics.map( topic => e( 'option', { value: topic }, topic ) ) )
                            ])
                        ])
                        , categoryErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'label' }, 'Link' )
                        , e( 'div', { className: 'control has-icons-left' }, [
                            e( 'input', { className: `input ${ linkErr ? 'is-danger' : '' }`, name: 'link', value: link, type: 'text', placeholder: 'Link', onChange: handleLinkChange } )
                            , e( 'span', { className: 'icon is-small is-left' }, e( 'i', { className: 'fa-solid fa-link' } ) )
                        ])
                        , linkErrMsg
                    ])
                    , e( 'div', { className: 'field' }, [
                        e( 'label', { className: 'checkbox' }, [
                            e( 'input', { name: 'accountNeeded', checked: accountNeeded, type: 'checkbox', onChange: handleAccountNeededChange } )
                            , 'Account required?'
                        ])
                    ])
                ])
            ])
            , e( 'footer', { className: 'modal-card-foot' }, [
                e( 'button', { className: `button is-success ${ saving ? 'is-loading' : '' }`, onClick: onSubmit }, 'Save' )
                , e( 'button', { className: 'button', onClick: closeModal }, 'Cancel' )
            ])
        ])
    ])
}

const AdminResources = props => {
    const { profile, config } = props

    const [ resources, setResources ] = useState( [] )
    const [ modalActive, setModalActive ] = useState( false )
    const [ errorMessage, setErrorMessage ] = useState( false )
    const [ successMessage, setSuccessMessage ] = useState( false )
    const [ editing, setEditing ] = useState( false )

    const loadResources = () => {
        fetch( '/resources/list', { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'There was a problem loading resources.' )

            res.json().then( data => setResources( data ) )
        })
    }

    useEffect( () => loadResources(), [] )

    const deleteResource = ( resource, ev ) => {
        fetch( `/resources/delete/${ resource._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'Resource not deleted.' )

            setResources( resources.filter( r => r != resource ) )
        })
    }

    const newResource = ev => {
        setEditing( false )
        setModalActive( true )
    }

    const editResource = ( resource, ev ) => {
        setEditing( resource )
        setModalActive( true )
    }

    return e( 'div', { className: 'section' }, [
        e( 'p', { className: 'title is-4' }, 'Resources' )
        , e( 'div', { className: 'column is-6' }, [
            successMessage ? e( 'article', { className: 'message is-success' }, e( 'div', { className: 'message-body' }, successMessage ) ) : ''
            , errorMessage ? e( 'article', { className: 'message is-danger' }, e( 'div', { className: 'message-body' }, errorMessage ) ) : ''
        ])
        , e( 'button', { className: 'button is-link' }, [
            e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-plus' } ) )
            , e( 'span', { onClick: newResource }, 'New Resource' )
        ])
        , e( 'br' )
        , e( 'table', { className: 'table is-striped' }, [
            e( 'thead', {}, [
                e( 'tr', {}, [
                    e( 'th', {}, 'Name' )
                    , e( 'th', {}, 'Description' )
                    , e( 'th', {}, 'Category' )
                    , e( 'th', {}, 'Size' )
                    , e( 'th', { style: { whiteSpace: 'nowrap' } }, 'Account Needed?' )
                    , e( 'th', {}, 'Tools' )
                ])
            ])
            , e( 'tbody', {}, resources.map( resource => e( 'tr', {}, [
                e( 'td', { style: { whiteSpace: 'nowrap' } }, resource.name )
                , e( 'td', {}, resource.description )
                , e( 'td', { style: { whiteSpace: 'nowrap' } }, resource.category )
                , e( 'td', { style: { whiteSpace: 'nowrap' } }, resource.size )
                , e( 'td', {}, resource.accountNeeded ? 'Yes' : 'No' )
                , e( 'td', { style: { whiteSpace: 'nowrap' } }, [
                    e( 'button', { className: 'button is-info', onClick: ev => editResource( resource, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-wrench fa-lg' } ) ) )
                    , e( 'button', { className: 'button is-danger', onClick: ev => deleteResource( resource, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-trash fa-lg' } ) ) )
                ])
            ])))
        ])
        , e( NewEditResourceModal, { modalActive, setModalActive, editing, config, setSuccessMessage, setErrorMessage, loadResources } )
    ])
}

export default AdminResources
