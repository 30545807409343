const e = React.createElement
const { useState } = React

import BrandCard from './brandcard.js'

const Brands = () => {
    const cards = [
        {
            title: 'Development'
            , summary: 'We develop custom Discord integrations and companion software for PC games.'
            , fontClass: 'uni-ug-font'
            , fontSize: '2em'
            , iconClass: 'fa-solid fa-code'
            , link: 'https://www.discord.gg/5BBFzyH'
            , linkLabel: 'Find us on Discord!'
            , link2: 'mailto:rpsirois@gmail.com'
            , link2Label: 'Email us!'
        }
        , {
            title: 'dir.gg'
            , summary: 'Software as a service project which allows users to create an analog to a phone extension which may be configured to redirect traffic in various ways.'
            , fontClass: 'uni-dirgg-font'
            , fontSize: '2em'
            , iconClass: 'fa-solid fa-user-xmark'
            , link: 'https://dir.gg'
            , linkLabel: 'Learn more!'
        }
        , {
            title: 'Rogue Element'
            , summary: 'We shoot things in FPS games.'
            , fontClass: 'uni-re-font'
            , fontSize: '2em'
            , iconClass: 'fa-solid fa-gamepad'
            , link: 'https://www.youtube.com/channel/UCZTI-z9kXuEJVpc-OjqHmig/playlists'
            , linkLabel: 'Jump into the action!'
        }
        , {
            title: '4 v All'
            , summary: 'MMORPG guild name.'
            , fontClass: 'uni-4vall-font'
            , fontSize: '2em'
            , iconClass: 'fa-solid fa-gamepad'
            , link: false
        }
        , {
            title: 'CONSTANT VOYAGER'
            , summary: 'MMORPG guild name.'
            , fontClass: 'uni-cv-font'
            , fontSize: '1.5em'
            , iconClass: 'fa-solid fa-gamepad'
            , link: false
        }
        , {
            title: 'Friends Forever'
            , summary: 'It really means friendly fire...'
            , fontClass: 'uni-ff-font'
            , fontSize: '2em'
            , iconClass: 'fa-solid fa-gamepad'
            , link: false
        }
    ]

    return e( 'div', { className: 'section' }, e( 'div', { className: 'row columns is-multiline' }, cards.map( c => e( 'div', { className: 'column is-4' }, [ e( BrandCard, c ) ]) ) ) )
}

export default Brands
