const e = React.createElement
const { useState } = React

const MediaCard = ( props ) => {
    const { title, date, link, type } = props

    return e( 'div', { className: 'card large' }, [
        e( 'header', { className: 'card-header' }, [
            e( 'p', { className: 'card-header-title' }, title )
        ])
        , e( 'div', { className: 'card-content' }, [
            e( 'div', { className: 'content' }, [
                e( 'video', { preload: 'metadata', controls: true }, [
                    e( 'source', { src: link, type: 'video/mp4' } )
                ])
            ])
        ])
        , e( 'div', { className: 'card-footer' }, [
            e( 'p', {}, date )
        ])
    ])
}

export default MediaCard
