import moment from 'moment-timezone'
const e = React.createElement
const { useState, useEffect } = React

const MemberResetPasswordModal = props => {
    const { resetModalActive, setResetModalActive, selectedMember, confirmReset } = props

    const closeModal = ev => {
        setResetModalActive( false )
    }

    const memberDisplayNamePossessive = selectedMember ? `${ selectedMember.username }${ selectedMember.username.endsWith( 's' ) ? '\'' : '\'s' }` : ''

    return e( 'div', { className: `modal ${ resetModalActive ? 'is-active' : '' }` }, [
        e( 'div', { className: 'modal-background' } )
        , e( 'div', { className: 'modal-card' }, [
            e( 'header', { className: 'modal-card-head' }, [
                e( 'p', { className: 'modal-card-title' }, 'Reset Member Password' )
                , e( 'button', { className: 'delete', onClick: closeModal } )
            ])
            , e( 'section', { className: 'modal-card-body' }, [
                e( 'p', {}, [
                    e( 'span', {}, 'Resetting a member\'s password will set it to ' )
                    , e( 'code', {}, 'unilovesyou' )
                    , e( 'span', {}, ' at which point they will need to log in and immediately change their password.' )
                ])
                , e( 'p', {}, `Are you sure you want to reset ${ memberDisplayNamePossessive } password?` )
            ])
            , e( 'footer', { className: 'modal-card-foot' }, [
                e( 'button', { className: 'button is-success', onClick: confirmReset }, 'Yes' )
                , e( 'button', { className: 'button is-danger', onClick: closeModal }, 'No' )
            ])
        ])
    ])
}

const MemberDeleteModal = props => {
    const { deleteModalActive, setDeleteModalActive, selectedMember, confirmDelete } = props

    const closeModal = ev => {
        setDeleteModalActive( false )
    }

    return e( 'div', { className: `modal ${ deleteModalActive ? 'is-active' : '' }` }, [
        e( 'div', { className: 'modal-background' } )
        , e( 'div', { className: 'modal-card' }, [
            e( 'header', { className: 'modal-card-head' }, [
                e( 'p', { className: 'modal-card-title' }, 'Delete Member' )
                , e( 'button', { className: 'delete', onClick: closeModal } )
            ])
            , e( 'section', { className: 'modal-card-body' }, [
                , e( 'p', {}, `Are you sure you want to delete ${ selectedMember ? selectedMember.username : '' }?` )
            ])
            , e( 'footer', { className: 'modal-card-foot' }, [
                e( 'button', { className: 'button is-success', onClick: confirmDelete }, 'Yes' )
                , e( 'button', { className: 'button is-danger', onClick: closeModal }, 'No' )
            ])
        ])
    ])
}

const MemberRolesModal = props => {
    const { config, rolesModalActive, setRolesModalActive, selectedMember, members, setMembers, setErrorMessage, setSuccessMessage } = props

    const defaults = {
        roles: []
    }

    const [ roles, setRoles ] = useState( selectedMember ? selectedMember.roles : defaults.roles )
    const [ saving, setSaving ] = useState( false )

    // this allows form to update when editing state is set on the parent
    useEffect( () => {
        setRoles( selectedMember ? selectedMember.roles : defaults.roles )
    }, [ props ] )

    const closeModal = ev => {
        setRolesModalActive( false )
    }

    const saveRoles = ev => {
        ev.preventDefault()
        
        setSaving( true )

        fetch( `/profile/update/${ selectedMember._id }`, {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                roles
            })
        })
        .then( res => {
            if ( res.ok ) {
                setSuccessMessage( `Roles for ${ selectedMember.username } updated.` )

                setMembers( members.map( m => {
                    if ( selectedMember == m ) {
                        m.roles = roles
                    }

                    return m
                }) )
            } else {
                setErrorMessage( 'Something went wrong.' )
            }
            
            setSaving( false )
            setRolesModalActive( false )
        })
    }

    const handleClick = ( role, ev ) => {
        const { checked } = ev.target

        if ( roles.indexOf( role.role ) < 0 ) {
            let newRoles = roles.slice()

            newRoles.push( role.role )

            return setRoles( newRoles )
        }

        setRoles( roles.filter( r => {
            if ( r == role.role ) return checked
            return true
        }) )
    }

    return e( 'div', { className: `modal ${ rolesModalActive ? 'is-active' : '' }` }, [
        e( 'div', { className: 'modal-background' } )
        , e( 'div', { className: 'modal-card' }, [
            e( 'header', { className: 'modal-card-head' }, [
                e( 'p', { className: 'modal-card-title' }, 'Manage Member Roles' )
                , e( 'button', { className: 'delete', onClick: closeModal } )
            ])
            , e( 'section', { className: 'modal-card-body' }, config.roles.map( role => e( 'div', {}, e( 'label', { className: 'checkbox' }, [
                    e( 'input', { type: 'checkbox', onChange: ev => handleClick( role, ev ) , value: role.role, checked: roles.indexOf( role.role ) >= 0 } )
                    , role.label
                ]) )
            ))
            , e( 'footer', { className: 'modal-card-foot' }, [
                e( 'button', { className: 'button is-success', onClick: saveRoles }, 'Save' )
                , e( 'button', { className: 'button', onClick: closeModal }, 'Cancel' )
            ])
        ])
    ])
}

const AdminMembers = props => {
    const { profile, config } = props

    const [ members, setMembers ] = useState( [] )
    const [ resetModalActive, setResetModalActive ] = useState( false )
    const [ deleteModalActive, setDeleteModalActive ] = useState( false )
    const [ rolesModalActive, setRolesModalActive ] = useState( false )
    const [ selectedMember, setSelectedMember ] = useState()
    const [ errorMessage, setErrorMessage ] = useState( false )
    const [ successMessage, setSuccessMessage ] = useState( false )

    const loadMembers = () => {
        fetch( '/members', { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'There was a problem loading members.' )

            res.json().then( data => setMembers( data ) )
        })
    }

    useEffect( () => loadMembers(), [] )

    const confirmReset = () => {
        fetch( `/resetPassword/${ selectedMember.key }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( `There was a problem resetting password for ${ selectedMember.username }.` )
            setSuccessMessage( `Password reset for ${ selectedMember.username }.` )
            setResetModalActive( false )
        })
    }

    const resetPassword = ( member, ev ) => {
        setSelectedMember( member )
        setResetModalActive( true )
    }

    const confirmDelete = () => {
        fetch( `/members/delete/${ selectedMember._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) {
                setErrorMessage( 'Member not deleted.' )
            } else {
                setMembers( members.filter( m => m != selectedMember ) )
            }

            setDeleteModalActive( false )
        })
    }

    const deleteMember = ( member, ev ) => {
        setSelectedMember( member )
        setDeleteModalActive( true )
    }

    const editMemberRoles = ( member, ev ) => {
        setSelectedMember( member )
        setRolesModalActive( true )
    }

    const canEditRoles = profile.roles.indexOf( 'adminMembers' ) >= 0 || profile.roles.indexOf( 'fullAdmin' ) >= 0

    return e( 'div', { className: 'section' }, [
        e( 'p', { className: 'title is-4' }, 'Members' )
        , e( 'div', { className: 'column is-6' }, [
            successMessage ? e( 'article', { className: 'message is-success' }, e( 'div', { className: 'message-body' }, successMessage ) ) : ''
            , errorMessage ? e( 'article', { className: 'message is-danger' }, e( 'div', { className: 'message-body' }, errorMessage ) ) : ''
        ])
        , e( 'table', { className: 'table is-striped' }, [
            e( 'thead', {}, [
                e( 'tr', {}, [
                    e( 'th', {}, 'ID' )
                    , e( 'th', {}, 'Username' )
                    , e( 'th', {}, 'Display Name' )
                    , e( 'th', {}, 'Password Type' )
                    , e( 'th', {}, 'Join Date' )
                    , e( 'th', {}, 'Roles' )
                    , e( 'th', {}, 'Tools' )
                ])
            ])
            , e( 'tbody', {}, members.map( member => e( 'tr', {}, [
                e( 'td', {}, member._id )
                , e( 'td', {}, member.key )
                , e( 'td', {}, member.username )
                , e( 'td', {}, member.passwordType )
                , e( 'td', {}, moment( member.timestamp ).tz( moment.tz.guess() ).format( 'M/D/YYYY' ) )
                , e( 'td', {}, e( 'div', { className: 'tags' }, member.roles.map( role => e( 'span', { className: 'tag is-rounded is-white' }, role ) ) ) )
                , e( 'td', {}, [
                    e( 'button', { className: 'button is-warning', onClick: ev => resetPassword( member, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-unlock-keyhole fa-lg' } ) ) )
                    , canEditRoles ? e( 'button', { className: 'button is-info', onClick: ev => editMemberRoles( member, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-id-card fa-lg' } ) ) ) : ''
                    , member._id != profile._id && member.key != 'eidy' ? e( 'button', { className: 'button is-danger', onClick: ev => deleteMember( member, ev ) }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-trash fa-lg' } ) ) ) : ''
                ])
            ])))
        ])
        , e( MemberResetPasswordModal, { resetModalActive, setResetModalActive, selectedMember, confirmReset } )
        , e( MemberDeleteModal, { deleteModalActive, setDeleteModalActive, selectedMember, confirmDelete } )
        , e( MemberRolesModal, { config, rolesModalActive, setRolesModalActive, selectedMember, members, setMembers, setErrorMessage, setSuccessMessage } )
    ])
}

export default AdminMembers
