const e = React.createElement
const { useState } = React

const BrandCard = ( props ) => {
    const { title, summary, fontClass, fontSize, iconClass, link, linkLabel, link2, link2Label, link3, link3Label } = props

    return e( 'div', { className: 'card large' }, [
        e( 'header', { className: 'card-header' }, [
            e( 'p', { className: `card-header-title ${ fontClass }`, style: { fontSize: fontSize || '1em' } }, title )
            , e( 'span', { className: 'card-header-icon' }, [
                e( 'i', { className: iconClass } )
            ])
        ])
        , e( 'div', { className: 'card-content' }, [
            e( 'div', { className: 'content' }, summary )
        ])
        , link ? e( 'div', { className: 'card-footer' }, [
            e( 'a', { className: 'card-footer-item', href: link, target: '_blank' }, linkLabel )
            , link2 ? e( 'a', { className: 'card-footer-item', href: link2, target: '_blank' }, link2Label ) : ''
            , link3 ? e( 'a', { className: 'card-footer-item', href: link3, target: '_blank' }, link3Label ) : ''
        ]) : ''
    ])
}

export default BrandCard
