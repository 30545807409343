const e = React.createElement

import Profile from './profile.js'

const ProfileBtn = ( props ) => {
    const { changeView, profile, config } = props

    const onClick = ev => {
        changeView( e( Profile, { profile, config, changeView } ) )
    }

    return e( 'a', { className: 'button is-info', onClick }, `Welcome back, ${ profile.username }!` )
}

export default ProfileBtn
