const e = React.createElement

const QRCode = ({ qrCode, profile }) => {
    return e( 'div', { className: 'section' }, [
        e( 'div', { className: 'columns is-centered' }, [
            e( 'div', { className: 'column is-4' }, [
                e( 'div', { className: 'card' }, [
                    e( 'header', { className: 'card-header' }, e( 'p', { className: 'card-header-title' }, [
                        e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-key' } ) )
                        , e( 'span', {}, profile.key )
                    ]) )
                    , e( 'div', { className: 'card-image' }, [
                        e( 'figure', { className: 'image is-1by1' }, e( 'img', { src: qrCode, alt: 'QR Code' } ) )
                    ])
                    , e( 'div', { className: 'card-content' }, e( 'div', { className: 'content' }, [
                        e( 'article', { className: 'message is-warning' }, [
                            e( 'div', { className: 'message-header' }, e( 'p', {}, 'THIS WILL ONLY BE DISPLAYED ONCE!' ) )
                            , e( 'div', { className: 'message-body' }, 'You need to scan this in your authenticator to produce time-based passcodes to log into this account. Make sure to save it now!' )
                        ])
                    ]) )
                ])
            ])
        ])
    ])
}

export default QRCode
