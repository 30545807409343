import moment from 'moment-timezone'
const e = React.createElement
const { useState, useEffect } = React

import QRCode from './qrCode.js'

const ProfileSummary = props => {
    const { profile, changeView } = props

    const [ themesongs, setThemesongs ] = useState( [] )
    const [ attributes, setAttributes ] = useState( [] )
    const [ username, setUsername ] = useState( profile.username )
    const [ password, setPassword ] = useState( '' )
    const [ confirmPassword, setConfirmPassword ] = useState( '' )
    const [ errorMessage, setErrorMessage ] = useState( false )
    const [ successMessage, setSuccessMessage ] = useState( false )
    const [ rotationStrategy, setRotationStrategy ] = useState( profile.tsRotationStrategy )
    const [ debounce, setDebounce ] = useState( profile.tsDebounce )
    const [ usernameSaving, setUsernameSaving ] = useState( false )
    const [ passwordSaving, setPasswordSaving ] = useState( false )

    const handleUsernameChange = ev => {
        setUsername( ev.target.value.trim() )
    }

    const handlePasswordChange = ev => {
        setPassword( ev.target.value )
    }

    const handleConfirmPasswordChange = ev => {
        setConfirmPassword( ev.target.value )
    }

    const handleSaveUsername = ev => {
        setUsernameSaving( true )

        fetch( `/profile/update/${ profile._id }`, {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                username
            })
        })
        .then( res => {
            if ( !res.ok ) {
                setErrorMessage( 'Display name not updated.' )
            } else {
                setSuccessMessage( `Display name set to "${ username }".` )
            }

            setUsernameSaving( false )
        })
    }

    const handleSavePassword = ev => {
        setPasswordSaving( true )

        // TODO validate pw

        fetch( `/changePassword/password/${ profile.key }/${ password }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) {
                setErrorMessage( 'Password not updated.' )
            } else {
                setPassword( '' )
                setConfirmPassword( '' )
                setSuccessMessage( 'Password successfully changed.' )
            }

            setPasswordSaving( false )
        })
    }
    const handleSaveTotpPassword = ev => {
        setPasswordSaving( true )

        fetch( `/changePassword/totp/${ profile.key }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) {
                setErrorMessage( 'TOTP key not regenerated.' )
                return setPasswordSaving( false )
            }

            res.json().then( data => {
                changeView( e( QRCode, { qrCode: data.msg, profile } ) )
            })
        })
    }

    const loadThemesongs = () => {
        fetch( `/profile/themesongs/metadata/${ profile.key }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'There was a problem loading your themesongs.' )

            res.json().then( data => {
                setThemesongs( data ) })
        })
    }

    const loadAttributes = () => {
        fetch( `/attributes/list/${ profile._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'There was a problem loading your attributes.' )

            res.json().then( data => setAttributes( data ) )
        })
    }

    useEffect( () => loadThemesongs(), [] )
    useEffect( () => loadAttributes(), [] )

    const handleUpload = ev => {
        let form = new FormData()
        form.append( 'clip', ev.target.files[0] )

        fetch( '/profile/themesongs', {
            method: 'POST'
            , body: form
        })
        .then( res => {
            if ( res.status == 500 ) return setErrorMessage( 'There was a problem processing the file.' )

            res.json().then( data => {
                if ( data.err ) return setErrorMessage( data.err )

                loadThemesongs()
            })
        })
    }

    const handleVolAdjChange = ( ts, ev ) => {
        const { value } = ev.target

        fetch( `/profile/themesongs/adjustvolume/${ ts._id }/${ value }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( `There was a problem updating themesong ${ ts.filename } volume.` )

            setSuccessMessage( `Themesong ${ ts.filename } volume updated to ${ value }.` )

            setThemesongs( themesongs.map( existTs => {
                if ( ts == existTs ) existTs.metadata.volAdj = value

                return existTs
            } ) )
        })
    }

    const handleSetActiveChange = ( ts, ev ) => {
        const { checked } = ev.target

        console.log( checked )

        if ( !checked ) return

        fetch( `/profile/themesongs/activate/${ ts._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( `There was a problem updating themesong ${ ts.filename } active status.` )

            setSuccessMessage( `Themesong ${ ts.filename } is now active!` )

            setThemesongs( themesongs.map( existTs => {
                existTs.metadata.active = ts == existTs

                return existTs
            } ) )
        })
    }

    const handleRotationStrategyChange = ( ev ) => {
        const { value } = ev.target

        fetch( `/profile/update/${ profile._id }`, {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                tsRotationStrategy: value
            })
        })
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'Themesong rotation strategy not updated.' )

            setRotationStrategy( value )

            setSuccessMessage( `Themesong rotation strategy set to "${ value }".` )
        })
    }

    const handleDebounceChange = ev => {
        const { value } = ev.target

        fetch( `/profile/update/${ profile._id }`, {
            credentials: 'same-origin'
            , method: 'POST'
            , headers: {
                'Content-Type': 'application/json'
            }
            , body: JSON.stringify({
                tsDebounce: value
            })
        })
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'Themesong debounce not updated.' )

            setDebounce( value )

            setSuccessMessage( `Themesong debounce set to "${ value }".` )
        })
    }

    const deleteThemesong = ( ts, ev ) => {
        fetch( `/profile/themesongs/delete/${ ts._id }`, { credentials: 'same-origin' } )
        .then( res => {
            if ( !res.ok ) return setErrorMessage( 'Themesong not deleted.' )

            setThemesongs( themesongs.filter( t => t != ts ) )
        })
    }

    const discordAccountId = attributes.filter( attr => attr.topic == 'Discord' && attr.key == 'accountId' )[0]

    return e( 'div', { className: 'row' }, [
        e( 'div', { className: 'columns' }, [
            e( 'div', { className: 'column is-6' }, [
                e( 'table', { className: 'table' }, [
                    e( 'tbody', {}, [
                        e( 'tr', {}, [
                            e( 'td', {}, e( 'strong', {}, 'Username' ) )
                            , e( 'td', {}, profile.key )
                            , e( 'td', {}, '' )
                        ])
                        , e( 'tr', {}, [
                            e( 'td', {}, e( 'strong', {}, 'Password Type' ) )
                            , e( 'td', {}, profile.passwordType )
                            , e( 'td', {}, '' )
                        ])
                        , e( 'tr', {}, [
                            e( 'td', {}, e( 'strong', {}, 'Account Created' ) )
                            , e( 'td', {}, moment( profile.timestamp ).tz( moment.tz.guess() ).format( 'M/D/YYYY' ) )
                            , e( 'td', {}, '' )
                        ])
                        , e( 'tr', {}, [
                            e( 'td', {}, e( 'strong', {}, 'Roles' ) )
                            , e( 'td', {}, e( 'div', { className: 'tags' }, profile.roles.map( role => e( 'span', { className: 'tag is-rounded is-white' }, role ) ) ) )
                            , e( 'td', {}, '' )
                        ])
                        , e( 'tr', {}, [
                            e( 'td', {}, e( 'strong', {}, 'Display Name' ) )
                            , e( 'td', {}, [
                                e( 'input', { className: 'input', type: 'text', placeholder: 'Display Name', name: 'username', value: username, onChange: handleUsernameChange } )
                            ])
                            , e( 'td', {}, [
                                e( 'button', { className: 'button is-success', onClick: handleSaveUsername }, [
                                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: 'fa-solid fa-check' } ) )
                                    , e( 'span', {}, 'Save' )
                                ])
                            ])
                        ])
                        , e( 'tr', {}, [
                            e( 'td', {}, e( 'strong', {}, 'Change Password' ) )
                            , e( 'td', {}, profile.passwordType == 'password' ? [
                                e( 'input', { className: 'input', type: 'password', placeholder: 'Change Password', name: 'password', value: password, onChange: handlePasswordChange } )
                                , e( 'br' )
                                , e( 'input', { className: 'input', type: 'password', placeholder: 'Confirm Change Password', name: 'confirmPassword', value: confirmPassword, onChange: handleConfirmPasswordChange } )
                            ] : '' )
                            , e( 'td', {}, [
                                e( 'button', { className: 'button is-success', onClick: profile.passwordType == 'totp' ? handleSaveTotpPassword : handleSavePassword }, [
                                    e( 'span', { className: 'icon is-small' }, e( 'i', { className: `fa-solid ${ profile.passwordType == 'totp' ? 'fa-qrcode' : 'fa-check' }` } ) )
                                    , e( 'span', {}, profile.passwordType == 'totp' ? 'Regenerate TOTP Key' : 'Save' )
                                ])
                            ])
                        ])
                    ])
                ])
            ])
            , e( 'div', { className: 'column is-6' }, [
                successMessage ? e( 'article', { className: 'message is-success' }, e( 'div', { className: 'message-body' }, successMessage ) ) : ''
                , errorMessage ? e( 'article', { className: 'message is-danger' }, e( 'div', { className: 'message-body' }, errorMessage ) ) : ''
            ])
        ])
        , e( 'p', { className: 'title is-4' }, 'Themesong Settings' )
        , e( 'div', { className: 'file' }, [
            e( 'label', { className: 'file-label' }, [
                e( 'input', { className: 'file-input', type: 'file', name: 'clip', accept: 'audio/*', onChange: handleUpload } )
                , e( 'span', { className: 'file-cta' }, [
                    e( 'span', { className: 'file-icon' }, e( 'i', { className: 'fa-solid fa-upload' } ) )
                    , e( 'span', { className: 'file-label' }, 'Upload a new clip!' )
                ])
            ])
        ])
        , e( 'table', { className: 'table is-striped' }, [
            e( 'thead', {}, [
                e( 'tr', {}, [
                    e( 'th', {}, 'Filename' )
                    , e( 'th', {}, 'Audio' )
                    , e( 'th', {}, 'Volume Adjustment' )
                    , e( 'th', {}, 'Selected' )
                    , e( 'th', {}, 'Tools' )
                ])
            ])
            , e( 'tbody', {}, themesongs.map( ts => e( 'tr', { className: `${ ts.metadata.active ? 'is-selected' : '' }` }, [
                e( 'td', {}, ts.filename )
                , e( 'td', {}, e( 'audio', { controls: true, style: { height: '25px' } }, e( 'source', { src: `/profile/themesongs/play/${ ts._id }`, type: ts.metadata.mimetype } ) ) )
                , e( 'td', {}, e( 'div', { className: 'select' }, e( 'select', { value: ts.metadata.volAdj, onChange: ev => handleVolAdjChange( ts, ev ), value: ts.metadata.volAdj }, [
                    e( 'option', { value: 0.25 }, '25%' )
                    , e( 'option', { value: 0.5 }, '50%' )
                    , e( 'option', { value: 0.75 }, '75%' )
                    , e( 'option', { value: 1.0 }, '100%' )
                    , e( 'option', { value: 1.25 }, '125%' )
                    , e( 'option', { value: 1.5 }, '150%' )
                ]) ) )
                , e( 'td', {}, [
                    e( 'label', { className: 'radio' }, [
                        e( 'input', { type: 'radio', name: 'activeThemesong', onClick: ev => handleSetActiveChange( ts, ev ), value: ts._id, defaultChecked: ts.metadata.active } )
                        , 'Active?'
                    ])
                ])
                , e( 'td', {}, [
                    e( 'button', { className: 'button is-danger' }, e( 'span', { className: 'icon' }, e( 'i', { className: 'fa-solid fa-trash fa-lg', onClick: ev => deleteThemesong( ts, ev ) } ) ) )
                ])
            ])))
        ])
        , e( 'div', { className: 'columns' }, [
            e( 'div', { className: 'column is-3' }, [
                e( 'div', { className: 'field' }, [
                    e( 'label', { className: 'label' }, 'Themesong Rotation Strategy' )
                    , e( 'div', { className: 'control' }, [
                        e( 'div', { className: 'select is-primary' }, [
                            e( 'select', { value: rotationStrategy, onChange: handleRotationStrategyChange }, [
                                e( 'option', { value: 'loop' }, 'Loop' )
                                , e( 'option', { value: 'random' }, 'Random' )
                                , e( 'option', { value: 'selected' }, 'Selected' )
                            ])
                        ])
                    ])
                ])
            ])
            , e( 'div', { className: 'column is-3' }, [
                e( 'div', { className: 'field' }, [
                    e( 'label', { className: 'label' }, 'Themesong Debounce Duration' )
                    , e( 'div', { className: 'control' }, [
                        e( 'div', { className: 'select is-primary' }, [
                            e( 'select', { value: debounce, onChange: handleDebounceChange }, [
                                e( 'option', { value: 0 }, 'None' )
                                , e( 'option', { value: 60 }, '1 Minute' )
                                , e( 'option', { value: 300 }, '5 Minutes' )
                                , e( 'option', { value: 3600 }, '1 Hour' )
                                , e( 'option', { value: 86400 }, '1 Day' )
                            ])
                        ])
                    ])
                ])
            ])
        ])
        , discordAccountId ? e( 'span', {}, [
            e( 'span', {}, 'Your Universes account is linked to Discord account ' )
            , e( 'strong', {}, discordAccountId.value )
            , e( 'span', {}, '.' )
        ]) : e( 'a', { href: '/profile/discord/authorize' }, 'Link Discord Identity' )
        , e( 'br' )
        , e( 'a', { href: '/logout' }, 'Logout' )
    ])
}

export default ProfileSummary
